import { useState } from 'react';
import { post } from './axiosInstance';
import { UploadMovieTypeDto } from '../types/commonTypes';

const ROOT = 'my-movies';

export const usePostMyMovies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  const mutate = async (data: UploadMovieTypeDto) => {
    setIsLoading(true);
    try {
      const dataReceived = await post(ROOT, data);
      if (dataReceived) {
        setData(dataReceived);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);      
    }
  };

  return {
    data,
    setData,
    isLoading,
    error,
    setError,
    mutate
  };
};
