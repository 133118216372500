import React, { useEffect, useState } from 'react'
import MainLayout from '../../layout/MainLayout';
import MainContent from './MainContent';
import { Header } from '../../Header';
import type { MovieType } from '../../../types/commonTypes';
import { useGetMovies, useGetMyMovies } from '../../../services';
import { MovieList } from '../../MovieList';
import { MoviesContext } from '../../../context/MoviesContext';
import { FilterContext } from '../../../context/FilterContext';
import { Loading } from '../Loading';
import { Error } from '../Error';

const MainScreen: React.FC = () => {
  const [movies, setMovies] = useState<MovieType[]>();
  const [selectedMovie, setSelectedMovie] = useState<MovieType>();
  const [menuOption, setMenuOption] = useState<number>(0);

  const {data, error, isLoading, mutate} = useGetMovies();
  const {data: dataMyMovies, error: errorMyMovies, isLoading: isLoadingMyMovies, mutate: mutateMyMovies} = useGetMyMovies();

  useEffect(() => {
    if (menuOption === 1) {
      mutateMyMovies();
    } else {
      mutate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOption]);

  useEffect(() => {
    if(data) {
      setMovies(data);
      setSelectedMovie(data[0])      
    }
  }, [data]);

  useEffect(() => {
    if(dataMyMovies) {
      setMovies(dataMyMovies);
      setSelectedMovie(dataMyMovies[0])      
    }
  }, [dataMyMovies]);

  if (isLoading || isLoadingMyMovies) return <Loading />
  if (!movies || !selectedMovie || error || errorMyMovies) return <Error />

  return (
    <MoviesContext.Provider value={{movies, setSelectedMovie, selectedMovie}}>
      <FilterContext.Provider value={{menuOption, setMenuOption}}>
        <MainLayout
          wallpaper={
            menuOption === 1 ? 
              selectedMovie?.poster_path :
              `${process.env.REACT_APP_MOVIES_IMAGE_PATH}${selectedMovie?.poster_path}`
          } 
          mainContent={<MainContent />}
          header={<Header />}
          aside={<MovieList />}
        />
      </FilterContext.Provider>
    </MoviesContext.Provider>
  )
}

export default MainScreen;