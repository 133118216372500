import React, { useContext } from 'react';
import type { MovieType } from '../../types/commonTypes';
import { Box, Stack } from '@mui/material';
import { Typography } from '../ui';
import play from '../../assets/play.svg';
import { MoviesContext } from '../../context/MoviesContext';
import { FilterContext } from '../../context/FilterContext';

type Props = {
  movie?: MovieType;
}

const MovieListItem = ({movie}: Props) => {
  const moviesContext = useContext(MoviesContext);
  const filterContext = useContext(FilterContext);

  if (!movie) return null;

  const getBackgroundImage = () => {
    let baseUrl: string | undefined= process.env.REACT_APP_MOVIES_IMAGE_PATH;
    if (filterContext.menuOption === 1) {
      baseUrl = '';
    }

    return `linear-gradient(180deg, rgba(0, 0, 0, 0) 22.78%, #000000 122.69%), url(${baseUrl}${movie?.poster_path})`
  };

  return (
    <Box 
    onClick={() => moviesContext.setSelectedMovie(movie)}
    sx={{
      width: {md: 220, xs: '100%'},
      height: {md: 146, xs: '100%'}, 
      backgroundImage: getBackgroundImage(),
      backgroundSize: 'cover',
      backgroundPosition: 'top',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 2,
      paddingTop: 5
    }}>
      <Stack direction="column" alignItems="center" justifyContent={'space-between'}>
        <Box>
        </Box>
        <Box>
          <img src={play} alt="logo" width="40" height="40" />
        </Box>
        <Box>
          <Typography variant2="movieListItemTitle">{movie?.title}</Typography>
        </Box>
      </Stack>
    </Box>
  )
}

export default MovieListItem;