import React from 'react'
import { Avatar, Badge, Box, IconButton, Stack } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MainMenu from './MainMenu/MinMenu';

type Props = {
  hideMenu?: boolean;
  hideNotifications?: boolean;
  hideAvatar?: boolean;
}

const ToolBox = ({hideMenu = false, hideNotifications = false, hideAvatar = false}: Props) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {
        !hideMenu &&
        <Box>
          <MainMenu />
        </Box>
      }
      {
        !hideNotifications &&
        <Box>
          <Badge overlap="circular" variant="dot" color='success'
            sx={{
                '& .MuiBadge-badge': {
                  right: 15,
                  top: 16,
                },
              }              
            }
          >
            <IconButton aria-label="notifications">
              <NotificationsNoneOutlinedIcon sx={{color: '#FFFFFF'}}/>
            </IconButton>
          </Badge>
        </Box>
      }
      {
        !hideAvatar &&
        <Box>
          <Avatar
            alt="User"
            src="https://avatar.iran.liara.run/public"
            sx={{ width: 40, height: 40 }}
          />          
        </Box>
      }
    </Stack>
  )
}

export default ToolBox;