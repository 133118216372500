import { createContext } from "react";
import type { MovieType } from "../types/commonTypes";

export const MoviesContext = createContext(
  {
    movies: [{adult: false, id: 0, title: '', poster_path: '', vote_count: 0, release_date: ''}],
    selectedMovie: {adult: false, id: 0, title: '', poster_path: '', vote_count: 0, release_date: ''},
    setSelectedMovie: (movie: MovieType): void => {},
  }
);
