import { styled } from '@mui/material/styles';
import { Button as MuiButton } from '@mui/material';

interface StyledButtonProps {
  variant2?: 'primary' | 'transparent' | 'light' | 'dark' | 'text';
};

const _BaseButton = styled(MuiButton)`
  width: 248px;
  height: 56px;
  gap: 0px;
  border-radius: 0px;
  border: 0px;
  font-family: Bebas Neue;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  letter-spacing: 4px;
  cursor: pointer !important;
  `;
  
  export const Button = styled(_BaseButton)`
  ${({variant2}: StyledButtonProps) => {
    let retVal = 'background-color: #242424; color: #ffffff;';
    switch (variant2) {
      case 'primary':
        break;
      case 'transparent':
        retVal = 'background-color: rgba(36, 36, 36, 0.5); color: #ffffff; border: 1px solid #FFFFFF80';
        break;
      case 'light':
        retVal = 'background-color: #ffffff; color: #242424;';
        break;
      case 'dark':
        retVal = 'background-color: rgba(255, 255, 255, 0.5); color: #242424;';
        break
      case 'text':
        retVal = 'background-color: rgba(0, 0, 0, 0); color: #FFFFFF;;';
        break
      default:
        break;
    }
    return retVal;
  }};  
`;