import React, { useContext } from 'react';
import MovieListItem from './MovieListItem';
import { Stack } from '@mui/material';
import type { MovieType } from '../../types/commonTypes';
import { MoviesContext } from '../../context/MoviesContext';
import Filter from './Filter';


const MovieList: React.FC = () => {
  const moviesContext = useContext(MoviesContext);
  const movies: MovieType[] | undefined = moviesContext.movies;

  if (!movies) return null;

  return (
    <Stack sx={{width: 220}}>
      <Filter />
      <Stack spacing={2}>
      {
        movies.map((movie, index) => {
          if (index > 3) return null;
          return <MovieListItem movie={movie} key={movie.id || index} />
        })
      }
      </Stack>
    </Stack>
  )
}

export default MovieList;
