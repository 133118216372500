import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControl, IconButton, Button as MuiButton, Stack, TextField } from '@mui/material';
import { Typography, Button } from '../ui';
import clip from './../../assets/clip.svg';
import { usePostMyMovies, useUploadImage } from '../../services';
import { UploadMovieTypeDto } from '../../types/commonTypes';
import ProgressBar from './ProgressBar';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddMovie() {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState<File | null>(null);
  const [title, setTitle] = React.useState<string | null>(null);

  const {data, setData, error, setError, isLoading, mutate} = usePostMyMovies();
  const {
    data: uploadImageData,
    setData: uploadImageSetData,
    error: uploadImageError,
    setError: uploadImageSetError,
    isLoading: uploadImageIsLoading, 
    uploadProgress: uploadImageProgress,
    mutate: uploadImageMutate
  } = useUploadImage();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.value) {
      setTitle(e.target.value);
    }
  };

  const handleFileUpload = async () => {
    if (file && title) {
      uploadImageMutate(file);
    }
  };

  const saveMovieHandler = async () => {
    if (uploadImageData && title) {
      const uploadMovieDto: UploadMovieTypeDto = {
        title: title,
        poster_path: uploadImageData
      }

      try {
        await mutate(uploadMovieDto);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    saveMovieHandler();
    // eslint-disable-next-line
  }, [uploadImageData]);

  React.useEffect(() => {
    setFile(null);
    setTitle(null);
    setData(undefined);
    setError(false);
    uploadImageSetData('');
    uploadImageSetError(false);
    // eslint-disable-next-line
  }, [open]);

  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        startIcon={<AddIcon />}          
        variant2="text">Agregar película
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-paper	': {
            borderRadius: 0,
            backgroundColor: '#242424',
            color: '#fff',
            padding: 2,
          },
        }}
      >
        <DialogTitle marginTop={0}>
          <Typography variant2="modalTitle">Agregar película</Typography>
        </DialogTitle>
        <DialogContent sx={{minWidth: {md: 550}}}></DialogContent>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>          
        <DialogContent>
          <FormControl required sx={{width: '100%'}}>
            <Stack direction={'column'} alignItems={'center'} spacing={4}>
              <>
                {
                  isLoading || uploadImageIsLoading ? (
                    <ProgressBar progress={uploadImageProgress} error={uploadImageError}/>
                  ) : (
                      !error && !data &&(
                        <MuiButton
                        variant="text"
                        component="label"
                        sx={{
                          color: '#fff',
                          border: 'dashed',
                          padding: 4
                        }}
                      >
                        <Stack direction={{md: 'row', xs: 'column'}} spacing={1} alignItems={{md: 'flex-start', xs: 'center'}}>
                          <img src={clip} alt="menu" width="16" height="16" />
                          <Typography variant2="movieListItemTitle">
                              <strong>Agregá un archivo</strong> o arrastralo y soltalo aquí
                          </Typography>
                        </Stack>
                        <input
                          type="file"
                          onChange={handleFileChange}
                          hidden
                        />
                      </MuiButton>
    
                      )                    
                  )
                }
                {
                  error && (
                    <Typography variant2="movieListItemTitle">
                      <strong>Error al cargar el archivo</strong>
                    </Typography>
                  )
                }
                {
                  data && (
                    <Box>
                      <Typography variant2="movieListItemTitle">
                        <strong>¡Felicitaciones!</strong>
                        <br />
                        <br />
                        <br />
                        {title} fue correctamente subida.                        
                      </Typography>
                    </Box>
                  )  
                }
              </>
              {
                !data && (
                  <TextField
                    disabled={isLoading}
                    id="movie-name" 
                    placeholder='Título'
                    variant="standard" 
                    onChange={handleTitleChange}
                    sx={{
                      width: 240,
                      "& .MuiInput-root": {
                        color: "#ffffff",
                        "&:before": {
                          borderColor: "#FFFFFF",
                          borderWidth: "1px",
                        },
                      },
                      "& .MuiInput-input": {
                        textAlign: 'center',
                      },
                    }}
                  />
                )
              }
            </Stack>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', marginTop: 2, marginBottom: 3 }}>
          {
            data ? (
              <Button variant2="light" onClick={handleClose}>Ir a Home</Button>
            ): (
              <Button variant2="light" onClick={handleFileUpload} disabled={title === null || file === null || isLoading}>Subir película</Button>
            )
          }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
