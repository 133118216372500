import { styled } from '@mui/material/styles';
import { Typography as MuiTypography } from '@mui/material';

interface StyledTypographyProps {
  variant2: 'movieTitle' | 'movieSubtitle' | 'movieListTitle' | 'movieListItemTitle' | 'movieTitleMobile' | 'mainMenuOptions' | 'modalTitle';
};
  
  export const Typography = styled(MuiTypography)`
  ${({variant2}: StyledTypographyProps) => {
    let retVal = 'color: #FFFFFF;';
    switch (variant2) {
      case 'movieTitle':
        retVal = `
        font-size: 120px;
        font-weight: 700;
        line-height: 100px;
        letter-spacing: 16px;
        text-align: left;        
        color: #64EEBC;
        `;          
        break;
      case 'movieTitleMobile':
        retVal = `
        font-size: 76px;
        font-weight: 400;
        line-height: 77.5px;
        letter-spacing: 12px;
        text-align: center;        
        color: #64EEBC;
        `;          
        break;
      case 'movieSubtitle':
        retVal = `
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 4px;
        text-align: left;
        color: #FFFFFF;      
        `;
        break;
      case 'movieListTitle':
        retVal = `
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 4px;
        text-align: center;      
        color: #FFFFFF;      
        `;
        break;
      case 'movieListItemTitle':
        retVal = `
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 4px;
        text-align: center;
        color: #FFFFFF;      
        `;  
        break;
      case 'mainMenuOptions':
        retVal = `
        font-size: 22px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 4px;
        text-align: left;
        color: #FFFFFF;              
      `;  
        break;
      case 'modalTitle':
        retVal = `
          font-size: 20px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 4px;
          text-align: center;
          color: #64EEBC;          
      `;  
        break;
      default:
        break;
    }
    return retVal;
  }};  
`;