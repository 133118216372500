import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 10000,
});
 
export const get = (url: string) => axiosInstance.get(url).then(res => res.data);
export const post = (url: string, data: any) => axiosInstance.post(url, data, {headers: {
  'Content-Type': 'application/json'
}}).then(res => res.data);
