import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import menu from './../../../assets/menu.svg'
import { Typography } from '../../ui';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ToolBox from '../ToolBox';
import { IconButton, Stack } from '@mui/material';
import { AddMovie } from '../../AddMovie';
import { Logo } from '../../Logo';

const MainMenu = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: '100%', color: 'white' }} role="presentation">
      <List>
        {[
          'Inicio',
          'Series',
          'Peliculas',
          'Agregadas recientemente',
          'Populares',
          'Mis Películas',
          'Mi lista',
          'Agregar Película',
          'Cerrar Sesión'
        ].map((text, index) => (
          <ListItem key={text}>
            <ListItemButton>
              {
                index === 7 && (
                  <AddMovie />
                )
              }
              {
                index !== 7 && (
                  <ListItemText primary={<Typography variant2='mainMenuOptions'>{text}</Typography>} onClick={toggleDrawer(false)}/>
                )
              }
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <img src={menu} alt="menu" width="27" height="12" />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)} sx={{
        display: { xs: 'block' },
        '& .MuiDrawer-paper': { 
          boxSizing: 'border-box',
          width: '100%',
          backgroundColor: '#242424',
          padding: {xs: 0, md: 2}, 
          paddingLeft: {xs: 0, md: 8},
          paddingRight: {xs: 0, md: 8}
        },
      }}>
        <Box>
          <Box sx={{marginBottom: 4, marginLeft: 1, marginRight: 2, marginTop: 2}}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <IconButton aria-label="notifications" onClick={toggleDrawer(false)}>
                <CloseOutlinedIcon  sx={{color: '#FFFFFF'}} />
              </IconButton>
              <Box visibility={{md: 'hidden', xs: 'visible'}}>
                <Logo isMobile />
              </Box>
              <Box sx={{display: {md: 'block', xs: 'none'}}}>
                <ToolBox hideMenu />
              </Box>
              <Box sx={{display: {md: 'none', xs: 'block'}}}>
                <ToolBox hideMenu hideNotifications />
              </Box>
            </Stack>
          </Box>
          {DrawerList}
        </Box>
      </Drawer>
    </div>
  );
}

export default MainMenu;
