import { Box } from '@mui/material';
import { Logo } from '../Logo';
import { AddMovie } from '../AddMovie';
import { ToolBox } from '../ToolBox';

type Props = {}

const Header = (props: Props) => {
  return (
    <>
      <Box sx={{ display: { md: 'flex', xs: 'none' }, justifyContent: 'space-between', alignItems: 'center', height: 40 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box>
            <Logo />
          </Box>
          <Box>
            <AddMovie />
          </Box>
        </Box>
        <Box>
          <ToolBox />
        </Box>
      </Box>

      {/* Mobile */}
      <Box sx={{ display: { md: 'none', xs: 'flex' }, justifyContent: 'space-between', alignItems: 'center', height: 40 }}>
        <Box>
          <ToolBox hideAvatar hideNotifications/>
        </Box>
        <Box>
          <Logo isMobile />
        </Box>
        <Box>
          <ToolBox hideMenu hideNotifications />
        </Box>
      </Box>
    </>
  )
}

export default Header;
