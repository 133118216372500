import React from 'react';
import { Box, Fade, Stack } from '@mui/material';
import Logo from '../../../assets/logo.svg';
import { Typography } from '../../ui';


type Props = {}

const Loading = (props: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Stack spacing={5} direction='column' alignItems={'center'}>
        <Fade in={true}  timeout={2000}>
          <img src={Logo} alt="Logo" width={100} />
        </Fade>
        <Typography variant2='movieListItemTitle'>Cargando...</Typography>
      </Stack>
    </Box>
  )
}

export default Loading;