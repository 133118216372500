import { useState } from 'react';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from '../config/firebase';

export const useUploadImage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<string>('');
  const [error, setError] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const mutate = async (file: File) => {
    setIsLoading(true);
    try {
      const storage = getStorage();
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        }, 
        (error) => {
          setError(true);
          setIsLoading(false);
        }, 
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData(downloadURL);
            setIsLoading(false);
          });
        }
      );              
    } catch (error) {
      setError(true);      
    } 
  }

  return {
    data,
    setData,
    isLoading,
    uploadProgress,
    error,
    setError,
    mutate
  };
};
