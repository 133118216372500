import React from 'react';
import Fade from '@mui/material/Fade';
import logo from './../../assets/logo.svg'

type Props = {
  isMobile?: boolean;
}

const Logo = ({isMobile = false}: Props) => {
  return (
    <Fade in={true} timeout={1500}>          
      <img src={logo} alt="logo" width={isMobile ? 98 : 113} height={isMobile ? 28 : 34} />
    </Fade>
  )
}

export default Logo;
