import { createTheme } from "@mui/material";

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}

export const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Bebas Neue',
  },
});
