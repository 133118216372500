import React from 'react';
import { MainScreen } from '../screens';

function App() {
  return (
    <MainScreen />
  );
}

export default App;
