// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDnUX7aTsqy2qS6om0hc5BVGBcJnmMOAHc",
  authDomain: "liteflix-5dbb6.firebaseapp.com",
  projectId: "liteflix-5dbb6",
  storageBucket: "liteflix-5dbb6.appspot.com",
  messagingSenderId: "1010434626767",
  appId: "1:1010434626767:web:4c524e2d42661f7d78d3ba"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
export default firebase;
export {getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL};