import { useState } from 'react';
import { get } from './axiosInstance';

const ROOT = 'my-movies';

export const useGetMyMovies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  const mutate = async () => {
    setIsLoading(true);
    try {
      const dataReceived = await get(ROOT);
      if (dataReceived) {
        setData(dataReceived);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);      
    }
  };

  return {
    data,
    isLoading,
    error,
    mutate
  };
};
