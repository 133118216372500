import React, { useContext } from 'react'
import { Button, Typography } from '../../ui'
import { Box, Stack } from '@mui/material'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import AddIcon from '@mui/icons-material/Add';
import type { MovieType } from '../../../types/commonTypes';
import { MoviesContext } from '../../../context/MoviesContext';

const MainContent = () => {
  const moviesContext = useContext(MoviesContext);
  const selectedMovie: MovieType | undefined = moviesContext.selectedMovie;
  
  if (!selectedMovie) return null;
  
  return (
    <Stack sx={{marginBottom: 5}} spacing={2} direction={'column'}>
      <Box>
        <Typography variant2="movieSubtitle" sx={{textAlign: {md: 'left', xs: 'center'}}}>ORIGINAL DE <strong>LITEFLIX</strong></Typography>
      </Box>
      <Box>
        <Typography variant2="movieTitle" sx={{display: {xs: 'none', md: 'block'}}}>{selectedMovie?.title}</Typography>
        <Typography variant2="movieTitleMobile" sx={{display: {xs: 'block', md: 'none'}}}>{selectedMovie?.title}</Typography>
      </Box>
      <Stack spacing={2} direction={{md: 'row', xs: 'column'}} alignItems={'center'}>
        <Button startIcon={<PlayArrowOutlinedIcon />}>
          reproducir
        </Button>
        <Button startIcon={<AddIcon />} variant2='transparent'>
          mi lista
        </Button>
      </Stack>
    </Stack>
  )
}

export default MainContent