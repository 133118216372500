import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Typography } from '../ui';
import { Stack } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { FilterContext } from '../../context/FilterContext';

const options = [
  'Populares',
  'Mis películas',
];

export default function SimpleListMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const filterContext = React.useContext(FilterContext);

  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    filterContext.setMenuOption(index);
    setAnchorEl(null);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List>
        <ListItemButton
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          sx={{padding: 0}}
        >
          <ListItemText>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <Typography variant2='movieListItemTitle'>
                Ver: <strong>{options[filterContext.menuOption]}</strong>
              </Typography>
              <Typography variant2='movieListItemTitle'>
                {open ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
              </Typography>
            </Stack>
          </ListItemText>
        </ListItemButton>
      </List>
      <Menu
        id="movie-filter"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
          sx: {bgcolor: '#242424', color: '#fff'}
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#242424',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}        
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={241}>
              <Typography variant2='movieListItemTitle'>{option}</Typography>
              <Typography variant2='movieListItemTitle'>
                {index === filterContext.menuOption ? <CheckOutlinedIcon /> : null}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}