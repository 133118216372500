import { Box, Container, Fade, Stack } from '@mui/material';
import React from 'react'

type Props = {
  wallpaper: string;
  mainContent: React.ReactNode;
  header: React.ReactNode;
  aside: React.ReactNode;
}

const MainLayout = ({wallpaper, mainContent, header, aside}: Props) => {
  return (
    <Fade in={true} timeout={1500}>
      <Box sx={{
        backgroundImage: {
          xs: `linear-gradient(to bottom, rgba(36, 36, 36, 0) 0%, #242424 50%), url('${wallpaper}')`,
          sm: `linear-gradient(to bottom, rgba(36, 36, 36, 0) 0%, #242424 50%), url('${wallpaper}')`,
          md: `url('${wallpaper}')`
        },
        backgroundColor: '#242424',
        backgroundSize: {md: 'cover', sm: 'cover', xs: '280%'},
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        overflow: 'hidden',
        display: 'flex',
      }}>
        <Container>
          <Box sx={{marginTop: 3}}>
            {header}
          </Box>
          <Stack direction={{md: 'row', xs: 'column'}} height={{md: '85vh'}} justifyContent={'space-between'} marginTop={{md: 0, xs: 20}}>
            <Box alignContent={'flex-end'} alignItems={'flex-end'} display={{md:'flex'}}>
              {mainContent}
            </Box>
            <Box overflow = 'scroll' minWidth={220} display={'flex'} justifyContent={'center'} marginBottom={5} marginTop={{md: 10}}>
              {aside}
            </Box>
          </Stack>
        </Container>
      </Box>
    </Fade>
  )
}

export default MainLayout;