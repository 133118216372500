
import React from 'react';
import { Box, LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { Typography } from '../ui';

type Props = {
  progress: number;
  error: boolean;
}

const ProgressBar: React.FC<Props>  = ({progress, error}: Props) => {

  const BorderLinearProgress= styled(LinearProgress)(() => ({
    height: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: error ? '#FF0000' : 'rgba(255, 255, 255, 0.5)'
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: 'rgba(100, 238, 188, 1)'
    },
  }));
  
  return (
    <Box width = '100%'>
      {
        error ?(
          <Typography variant2='movieListItemTitle' sx={{textAlign: 'left'}}>¡ERROR! NO SE PUDO CARGAR LA PELÍCULA</Typography>
        ) :(
          <Typography variant2='movieListItemTitle' sx={{textAlign: 'left'}}>cargando {Math.trunc(progress) ?? 0}%</Typography>
        )
      }
      <BorderLinearProgress value={progress} variant="determinate" sx={{ width: '100%', marginTop: '20px' }} />
    </Box>
  )
}

export default ProgressBar;