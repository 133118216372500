import React from 'react';
import { Box, Fade, Stack } from '@mui/material';
import Logo from '../../../assets/logo.svg';
import { Button, Typography } from '../../ui';


type Props = {}

const Error = (props: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Stack spacing={5} direction='column' alignItems={'center'}>
        <Fade in={true}  timeout={2000}>
          <img src={Logo} alt="Logo" width={100} />
        </Fade>
        <Typography variant2='movieListItemTitle'>Ups! Ha ocurrido un error, por favor intente de nuevo.</Typography>
        <Button variant2='transparent' onClick={() => window.location.reload()}>ir a home</Button>
      </Stack>
    </Box>
  )
}

export default Error;